import { logoutUser } from "./Auth";
import { ToastContainer, toast } from "react-toastify";
import axios from "./AxiosCustom";

import "./../../Config";


import {
    swal,
    swalQuestion2,
    SUCCESS,
    INFO,
    ERROR,
    WARNING,
} from "../Partials/SweetAlert";


export let Expira = () => {
    swalQuestion2(
        "¡Your session has expired!",
        "You must log in again",
        WARNING,
        false
    ).then(async function(isConfirm) {
        if (isConfirm) {
            logoutUser();
        }
    });
}

export let Toast = (txt, type = 1) => {
    if (type == 1) {
        toast.success(txt, {
            theme: "dark",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.error(txt, {
            theme: "dark",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export let Permisos = async(modulo) => {
    const api_base = window.$api_base;
    let res;
    await axios
        .get(api_base + "get-menu")
        .then((response) => {
            res = response.data.data.filter((e) => e.module == modulo);
        })
        .catch((error) => {
            console.log(error);
            res = false;
        });
    return res;
}

export let getMenu = async() => {
    const api_base = window.$api_base;
    let res;
    await axios
        .get(api_base + "get-menu")
        .then((response) => {
            res = response.data.data;
        })
        .catch((error) => {
            console.log(error);
            res = false;
        });
    return res;
}

export let goTo = (self, path) => {
    self.props.history.push(path);
}