// Obtiene el nombre de usuario autenticado
export const getUser = () => {
        const userName = localStorage.getItem('PE_US');

        return userName || null;
    }
    // Obtiene el Token del usuario autenticado
export const getUser2 = () => {
    const userName = localStorage.getItem('PE_login');

    return userName || null;
}

// Elimina la localStorage del usuario autenticado
export const logoutUser = () => {
    localStorage.removeItem('PE_login');
    localStorage.removeItem('PE_US');

    // redirecciona al usuario al inicio al login
    window.location = '/login';
}