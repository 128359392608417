import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, /*Route,*/ Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PublicRoute from './components/Common/PublicRoute';
import PrivateRoute from './components/Common/PrivateRoute';
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

const waitFor = Tag => props => <Tag {...props} />;
const Login = lazy(() => import('./components/Login/Login'));
const Track = lazy(() => import('./components/traking/track'));
const Inicio = lazy(() => import('./components/Inicio/Inicio'));
const Almacenes = lazy(() => import('./components/General/Almacenes'));
const SSL = lazy(() => import('./components/General/SSL'));
const Permisos = lazy(() => import('./components/Roles Permisos/Permisos'));
const Roles = lazy(() => import('./components/Roles Permisos/Roles'));
const Usuarios = lazy(() => import('./components/Contactos/Usuarios'));
const Personal = lazy(() => import('./components/Contactos/Personal'));
const Clientes = lazy(() => import('./components/Contactos/Clientes'));
const Size = lazy(() => import('./components/General/Size'));
const Estados = lazy(() => import('./components/General/Estados'));
const Camiones = lazy(() => import('./components/General/Camiones'));
const Trabajos = lazy(() => import('./components/Trabajos/Trabajos'));
const PermissionError = lazy(() => import('./components/Common/PermissionError'));
const Main = lazy(() => import('./components/main/main'));
const Work = lazy(() => import('./components/main/work-view'));


const listofPages = [
    '/login',
    '/track'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const animationName = 'rag-fadeIn'

    if (listofPages.filter((d)=>d=='/'+currentKey).length >0) {
        return (
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <PublicRoute path="/login" component={waitFor(Login)} />
                        <PublicRoute path="/track" component={waitFor(Track)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    {/* <PublicRoute path="/login" component={waitFor(Login)} />
                                    <PublicRoute path="/track" component={waitFor(Track)} /> */}

                                    {/* <PublicRoute path="/track/:id" component={waitFor(Track)} /> */}
                                    <PrivateRoute path="/inicio" component={waitFor(Inicio)} />
                                    <PrivateRoute path="/main" component={waitFor(Main)} exact/>
                                    <PrivateRoute path="/work-view" component={waitFor(Work)} exact />
                                    <PrivateRoute path="/almacenes" component={waitFor(Almacenes)} />
                                    <PrivateRoute path="/ssl" component={waitFor(SSL)} />
                                    <PrivateRoute path="/permisos" component={waitFor(Permisos)} />
                                    <PrivateRoute path="/roles" component={waitFor(Roles)} />
                                    <PrivateRoute path="/usuarios" component={waitFor(Usuarios)} />
                                    <PrivateRoute path="/personal" component={waitFor(Personal)} />
                                    <PrivateRoute path="/clientes" component={waitFor(Clientes)} />
                                    <PrivateRoute path="/size" component={waitFor(Size)} />
                                    <PrivateRoute path="/estados" component={waitFor(Estados)} />
                                    <PrivateRoute path="/camiones" component={waitFor(Camiones)} />
                                    <PrivateRoute path="/trabajos" component={waitFor(Trabajos)} />
                                    {/* Error de permisos */}
                                    <PrivateRoute path="/403" component={waitFor(PermissionError)} />

                                    <Redirect to="/inicio" />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);
