import axios from 'axios';
import { getUser2 } from './Auth';

// axios instance for making requests 
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
    // add token to request headers
    // config.headers['x-api-key'] = getUser();
    config.headers['Authorization'] = `Bearer ${getUser2()}`;


    return config;
});

export default axiosInstance;