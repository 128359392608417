import axios from "./components/Common/AxiosCustom";
import "./Config";

const api_base = window.$api_base;

let temp = [];

// divición menú General
let subGeneral = [];
let subRoles = [];
let subContactos = [];

export const getMenu = async () => {
    await axios
        .get(api_base + "get-menu")
        .then((response) => {
            /**************** Sub Menú's ******************/
            /**********************************************/

            /**************** Sub General ******************/
            // Camiones
            if (response.data.data.filter((d) => d.path == '/camiones').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/camiones');
                subGeneral.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }
            // SSL
            if (response.data.data.filter((d) => d.path == '/ssl').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/ssl');
                subGeneral.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }
            // Almacenes
            if (response.data.data.filter((d) => d.path == '/almacenes').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/almacenes');
                subGeneral.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }

            // Size
            if (response.data.data.filter((d) => d.path == '/size').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/size');
                subGeneral.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }

            // Estados
            if (response.data.data.filter((d) => d.path == '/estados').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/estados');
                subGeneral.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }

            /******** Sub Permisos y Roles ************/

            // Roles
            if (response.data.data.filter((d) => d.path == '/roles').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/roles');
                subRoles.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }
            // Permisos
            if (response.data.data.filter((d) => d.path == '/permisos').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/permisos');
                subRoles.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }

            /******** Sub Contactos ************/

            // Clientes
            if (response.data.data.filter((d) => d.path == '/clientes').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/clientes');
                subContactos.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }
            // Personal
            if (response.data.data.filter((d) => d.path == '/personal').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/personal');
                subContactos.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }
            // Usuarios
            if (response.data.data.filter((d) => d.path == '/usuarios').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/usuarios');
                subContactos.push({
                    name: data[0].description,
                    path: data[0].path
                });
            }

            /**************** Menú's ******************/
            /******************************************/

            if (response.data.data.filter((d) => d.path == '/inicio').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/inicio');
                Menu.push({
                    name: data[0].description,
                    path: data[0].path,
                    icon: "icon-home",
                    translate: 'sidebar.nav.INICIO',
                });
            }


            /**************** Works *****************/
            if (response.data.data.filter((d) => d.path == '/main').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/main');
                Menu.push({
                    name: data[0].description,
                    path: data[0].path,
                    icon: "far fa-paper-plane",
                    translate: 'sidebar.nav.TRACK',

                });
            }


            /**************** Works *****************/
            if (response.data.data.filter((d) => d.path == '/trabajos').length > 0) {
                let data = response.data.data.filter((d) => d.path == '/trabajos');
                Menu.push({
                    name: data[0].description,
                    path: data[0].path,
                    icon: "far fa-folder-open",
                    translate: 'sidebar.nav.TRABAJOS',

                });
            }

            /**************** Contactos *****************/
            if (subContactos.length > 0) {
                Menu.push({
                    name: "Contacts",
                    icon: "icon-user",
                    translate: 'sidebar.nav.PERSONAL',
                    submenu: subContactos

                });
            }

            /**************** General *****************/
            if (subGeneral.length > 0) {
                Menu.push({
                    name: 'General',
                    icon: 'icon-globe',
                    translate: 'sidebar.nav.GENERAL',
                    submenu: subGeneral

                });
            }

            /**************** Permisos y Roles *****************/
            if (subRoles.length > 0) {
                Menu.push({
                    name: 'Permissions and Roles',
                    icon: 'icon-lock',
                    translate: 'sidebar.nav.ROLES',
                    submenu: subRoles

                });
            }

        })
        .catch((error) => {
            console.log(error);
        });
}


let Menu = [{
    heading: "Menú Principal",
    translate: "sidebar.heading.HEADER",
},];
export default Menu;