import sweetalert from "sweetalert";

export const SUCCESS = "success";
export const INFO = "info";
export const WARNING = "warning";
export const ERROR = "error";

export const swal = (title, text, type) => {
    sweetalert({
        title: title,
        text: text,
        icon: type,
        closeOnClickOutside: false,
    });
};

export const swalQuestion = (text,close=false) => {
    return sweetalert({
        title: '¿Are you sure to perform this action?',
        text: text,
        icon: 'info',
        closeOnClickOutside: false,
        buttons: {
            cancel: {
                text: 'No, cancel',
                value: null,
                visible: true,
                className: "",
                closeModal: true
            },
            confirm: {
                text: 'Yes, continue',
                value: true,
                visible: true,
                className: "",
                closeModal: close
            }
        }
    });
}

export const swalQuestion2 = (title,text,type,cancel = true) => {
    let cancelar = "";
    return sweetalert({
        title,
        text: text,
        icon: type,
        closeOnClickOutside: false,
        buttons: {
            confirm: {
                text: "Yes, continue",
                value: true,
                visible: true,
                className: "",
                closeModal: false,
            },
            cancel: {
                text: "No, cancel",
                value: null,
                visible: cancel,
                className: "",
                closeModal: false,
            }
        },
    });
};
