import React from 'react';
import { Redirect, Route } from 'react-router';
import { getUser,getUser2 } from './Auth';

const PrivateRoute = ({ component: Component, ...rest }) =>
{
    return (
        <Route {...rest}
            render={props => {
                return (getUser() && getUser2())? <Component {...props} />
                    : <Redirect to={{ pathname: '/login', state: { from: props.location }  }}></Redirect>
            }}
        />
    )
}

export default PrivateRoute;