/***********************************************************************/
/*             VARIABLES DE CONFIGURACION                              */
/*---------------------------------------------------------------------*/
/* Archivos con las variables de configuraciones globales del proyecto */
/***********************************************************************/

window.$ambiente = 1; // '0' => desarrollo | '1' => producción
window.$project_name = 'J1 Enterprise Login';
window.$company = 'J1 Enterprise Corp';
window.$version = '1.0.0';
window.$utlima_compilacion = '24/03/2022';
window.$KEY_CRYPTO = "JI)tdMGY_AaWYZf";
// APIs URL ambiente de producción


if (window.$ambiente) {
    window.$api_base = 'http://api.j1.nekodevelopment.com.do/api/';
}
// APIs URL ambiente de desarrollo
else {
    window.$api_base = 'http://127.0.0.1:4000/api/';
}