import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from 'react-router-dom';
// import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

import ToggleFullscreen from "../Common/ToggleFullscreen";
import HeaderRun from "./Header.run";

import Darkreader from "react-darkreader";


class Header extends Component {
    state = {
        darker: null,
    };

    componentDidMount() {
        HeaderRun();
    }

    toggleUserblock = (e) => {
        e.preventDefault();
        this.props.actions.toggleSetting("showUserBlock");
    };

    toggleOffsidebar = (e) => {
        e.preventDefault();
        this.props.actions.toggleSetting("offsidebarOpen");
    };

    toggleCollapsed = (e) => {
        e.preventDefault();
        this.props.actions.toggleSetting("isCollapsed");
        this.resize();
    };

    toggleAside = (e) => {
        e.preventDefault();
        this.props.actions.toggleSetting("asideToggled");
    };

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent("UIEvents");
        evt.initUIEvent("resize", true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    changeDark = async () => {
        const STORAGE_KEY = "dark";
        // console.log(this.state.darker);
        if (this.state.darker == 0) {
            this.updateLoca(1);
            this.setState({ darker: 1 });
        } else {
            this.setState({ darker: 0 });
            this.updateLoca(0);
        }
    };

    updateLoca(value) {
        localStorage.setItem("dark", value);
    }

    componentDidMount() {
        if (localStorage.getItem("dark")) {
            if (
                localStorage.getItem("dark") !== '0' &&
                localStorage.getItem("dark") !== '1'
            ) {
                localStorage.setItem("dark", 1);
                this.setState({ darker: 1 });
            }else{
                this.setState({ darker: localStorage.getItem("dark") });
            }
        } else {
            localStorage.setItem("dark", 1);
        }
    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                {/* START Top Navbar */}
                <nav className="navbar topnavbar" >
                    {/* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/inicio">
                            <div className="brand-logo">
                                <img
                                    className="img-fluid"
                                    src="img/logo.png"
                                    alt="App Logo"
                                    style={{ height: "35px" }}
                                />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img
                                    className="img-fluid"
                                    src="img/logo-black.png"
                                    alt="App Logo"
                                />
                            </div>
                        </a>
                    </div>
                    {/* END navbar header */}

                    {/* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            <a
                                href=""
                                className="nav-link d-none d-md-block d-lg-block d-xl-block"
                                onClick={this.toggleCollapsed}
                            >
                                <em className="fas fa-bars"></em>
                            </a>
                            <a
                                href=""
                                className="nav-link sidebar-toggle d-md-none"
                                onClick={this.toggleAside}
                            >
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                        {/*<li className="nav-item d-none d-md-block">
                            <a  className="nav-link" onClick={ this.toggleUserblock }>
                                <em className="icon-user"></em>
                            </a>
                        </li>*/}
                    </ul>
                    {/* END Left navbar */}
                    {/* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        {/* Fullscreen (only desktops) */}
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link" />
                        </li>
                        {/* END Offsidebar menu */}
                    </ul>
                    <ul className="navbar-nav flex-row">
                        <li className="nav-item">
                            {parseInt(this.state.darker) === 0 && (
                                <Darkreader
                                    defaultDarken
                                    onChange={() => {
                                        this.changeDark();
                                    }}
                                />
                            )}
                            {parseInt(this.state.darker) === 1 && (
                                <Darkreader
                                    onChange={() => {
                                        this.changeDark();
                                    }}
                                />
                            )}
                        </li>
                    </ul>

                    <ul className="navbar-nav flex-row">
                        {/* START Offsidebar button */}
                        <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="icon-settings"></em>
                            </a>
                        </li>
                        {/* END Offsidebar menu */}
                    </ul>
                    {/* END Right Navbar */}
                </nav>
                {/* END Top Navbar */}
            </header>
        );
    }
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
