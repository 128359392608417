import React, { Component } from 'react';
class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container" style={{borderTop:'#CFDBE2 solid 1px',backgroundColor:'white'}}>
                <span>Copyright &copy; {year} {window.$company}. All rights reserved.</span>
            </footer>
        );
    }

}

export default Footer;
