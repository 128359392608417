import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

import {
  TabContent, TabPane, Nav, NavItem, NavLink, Modal,
  ModalBody,
  Button,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { logoutUser } from "../Common/Auth";
import { swal, swalQuestion, INFO, ERROR, SUCCESS } from "../Partials/SweetAlert";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUser, getUser2 } from '../Common/Auth';
import './../../Config';
import axios from "../Common/AxiosCustom";
import { Expira, Toast } from "../Common/FuncionesComun";


const base_api = window.$api_base;


class Offsidebar extends Component {
  state = {
    activeTab: "settings",
    offsidebarReady: false,

    tipo_modal: "update",
    descripcion_modal: "Change Password",
    showChangePass: false,
    passOld: '',
    passNew: '',
    passNewRepeat: '',
    user_name: getUser()
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    // When mounted display the offsidebar
    this.setState({ offsidebarReady: true });
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.passNew) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('isDiferentPass', (value) => {
      if (value !== this.state.passOld) {
        return true;
      }
      return false;
    });
  }

  actionChangePass() {
    this.setState({ passOld: '', passNew: '', passNewRepeat: '', showChangePass: true });
    this.props.actions.toggleSetting("offsidebarOpen");
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule('isPasswordMatch');
    ValidatorForm.removeValidationRule('isDiferentPass');
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  // termina la sesión actual de un usuario autenticado
  logout = () => {
    this.props.actions.toggleSetting("offsidebarOpen");
    swalQuestion("Your session will end if you wish to continue...").then(function (
      isConfirm
    ) {
      if (isConfirm) logoutUser();
      else
        swal(
          "¡Warning!",
          "Your session will remain logged in as long as the browser is open.",
          INFO
        );
    });
  };

  // actualiza los criterios de evaluacion
  putPassword = async (e) => {
    e.preventDefault();

    await axios
      .put(base_api + "update-user", { password: this.state.passNew, type: '1', old: this.state.passOld })
      .then((response) => {
        if (response.data.type === 1) {
          Toast('Transaction Completed! Password change successful');
          this.setState({ passOld: '', passNew: '', passNewRepeat: '', showChangePass: false });
        } else if (response.data.type === 2) {
          Toast('Error! Changes could not be made, please try again later', 2);
        } else if (response.data.type === 3) {
          swal('Error!', response.data.message, ERROR);
        } else {
          Expira()
        }
        this.setState({ showModal: false })
      })
      .catch((error) => {
        swal("Error", error.message, ERROR);
      });

  };

  handleSettingCheckbox = (event) => {
    this.props.actions.changeSetting(event.target.name, event.target.checked);
  };

  handleThemeRadio = (event) => {
    this.props.actions.changeTheme(event.target.value);
  };

  render() {
    return (
      this.state.offsidebarReady && (
        <aside className="offsidebar">
          {/* START Off Sidebar (right) */}
          <nav>
            <div>
              {/* Tab panes */}
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="settings">
                  <h3 className="text-center text-thin mt-4">Configuration</h3>
                  <div className="pr-2 pl-2 pt-2">
                    <h4 className="text-muted text-thin pb-2">
                      User Options
                    </h4>
                    <div className="clearfix">
                      <div className="list-group-item list-group-item-action border-0" onClick={(e) => {
                        this.actionChangePass();
                      }}>
                        <div className="media">
                          <div className="media-body text-truncate">
                            <span className="icon-key mr-2"></span> Change Password

                          </div>
                        </div>
                      </div>

                      <div className="list-group-item list-group-item-action border-0" onClick={(e) => {
                        this.logout();
                      }}>
                        <div className="media">
                          <div className="media-body text-truncate">
                            <span className="icon-logout mr-2"></span> Sign off
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <h4 className="text-muted text-thin pb-2 pt-2">
                      Menu Options
                    </h4>
                    <div className="clearfix">
                      <p className="float-left">Fixed Menu</p>
                      <div className="float-right">
                        <label className="switch">
                          <input
                            id="chk-fixed"
                            type="checkbox"
                            name="isFixed"
                            checked={this.props.settings.isFixed}
                            onChange={this.handleSettingCheckbox}
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="pr-2 pl-2">
                    <div className="clearfix">
                      <p className="float-left">Collapsed Menu</p>
                      <div className="float-right">
                        <label className="switch">
                          <input
                            id="chk-collapsed"
                            type="checkbox"
                            name="isCollapsed"
                            checked={this.props.settings.isCollapsed}
                            onChange={this.handleSettingCheckbox}
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="pr-2 pl-2"></div>
                  <hr />
                  <div className="p-2">
                    <h4 className="text-muted text-thin">Theme</h4>
                    <div className="row row-flush mb-2">
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-e.css'} value='themes/theme-e.css' onChange={this.handleThemeRadio} />
                            <span className="icon-check"></span>
                            <span className="split">
                              <span className="color bg-info-dark"></span>
                              <span className="color bg-info"></span>
                            </span>
                            <span className="color bg-gray-dark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-f.css'} value='themes/theme-f.css' onChange={this.handleThemeRadio} />
                            <span className="icon-check"></span>
                            <span className="split">
                              <span className="color bg-green-dark"></span>
                              <span className="color bg-green"></span>
                            </span>
                            <span className="color bg-gray-dark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-g.css'} value='themes/theme-g.css' onChange={this.handleThemeRadio} />
                            <span className="icon-check"></span>
                            <span className="split">
                              <span className="color bg-purple-dark"></span>
                              <span className="color bg-purple"></span>
                            </span>
                            <span className="color bg-gray-dark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-h.css'} value='themes/theme-h.css' onChange={this.handleThemeRadio} />
                            <span className="icon-check"></span>
                            <span className="split">
                              <span className="color bg-danger-dark"></span>
                              <span className="color bg-danger"></span>
                            </span>
                            <span className="color bg-gray-dark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </nav>
          {/* END Off Sidebar (right) */}
          {/* Modal Insertar o Actualizar */}
          <Modal
            isOpen={this.state.showChangePass}
            fade={false}
            className="modal-lg"
          >
            <ModalHeader style={{ display: "block" }}>
              <div>
                <span
                  className="icon-close-modal"
                  style={{ float: "right" }}
                  onClick={() => this.setState({ showChangePass: false })}
                >
                  <i className="fa fa-times"></i>
                </span>
                <h4 className="mb-0">{this.state.descripcion_modal}</h4>
              </div>
              {/* <h4>  </h4> */}
            </ModalHeader>
            <ValidatorForm
              onSubmit={(e) => {
                this.putPassword(e);
              }}
            >
              <ModalBody className="mb-1 mt-2">
                <div className="col-xl-12">
                  {/* START card */}
                  <div className="card">
                    <div className="half-float ie-fix-flex">
                      <img className="img-fluid" src="img/bgprofile.png" alt="Demo" />
                      <div className="half-float-bottom">
                        <img className="img-thumbnail circle thumb128" src="img/profile.png" alt="Avatar" width="60" height="60" />
                        {/* <img className="img-thumbnail circle thumb128" src={base_api + "get-photo?t=" + getUser2()} alt="Profile" /> */}
                      </div>
                    </div>
                    <div className="card-body text-center">
                      <h3 className="m-0">{this.state.user_name}</h3>
                      {/* <p className="text-muted">Lead director</p> */}
                      <p>Change of password.</p>
                    </div>
                  </div>
                  {/* END card */}
                </div>
                <div className="row justify-content-center">
                  <div className="form-group col-lg-6 col-sm-12">
                    <TextValidator
                      name="passOld"
                      id="passOld"
                      label="Current Password"
                      type="password"
                      className="form-control mt-1 mb-4"
                      placeholder="Write the password"
                      value={this.state.passOld}
                      autoComplete='false'
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      validators={["required"]}
                      errorMessages={[
                        "The field is required"
                      ]}
                    />
                  </div>
                </div>
                <div className="row justify-content-center">

                  <div className="form-group col-lg-6 col-sm-12">
                    <TextValidator
                      className="form-control mb-4"
                      value={this.state.passNew}
                      name="passNew"
                      id="passNew"
                      placeholder="Write the password"
                      label="New Password"
                      type="password"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      validators={["required", "isDiferentPass"]}
                      errorMessages={[
                        "The field is required",
                        "The new password cannot be the same as the current one",
                      ]}
                    />
                  </div>
                </div>
                <div className="row justify-content-center">

                  <div className="form-group col-lg-6 col-sm-12">
                    <TextValidator
                      className="form-control mb-4"
                      value={this.state.passNewRepeat}
                      name="passNewRepeat"
                      id="passNewRepeat"
                      placeholder="Write password"
                      label="Repeat Password"
                      type="password"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      validators={["required", "isPasswordMatch"]}
                      errorMessages={[
                        "The field is required",
                        "Passwords do not match"
                      ]}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => this.setState({ showChangePass: false })}>
                  Close
                </Button>
                {this.state.tipo_modal === "add" ? (
                  <Button type="submit" color="info">
                    Add
                  </Button>
                ) : (
                  <Button color="success" type="submit">
                    Update
                  </Button>
                )}
              </ModalFooter>
            </ValidatorForm>
          </Modal>
        </aside>
      )
    );
  }
}

Offsidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
  theme: PropTypes.object,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  theme: state.theme,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offsidebar);
